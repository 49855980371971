<template>
  <v-container fluid class="pa-0">
    <v-card flat tile max-width="1200" class="mx-auto d-flex flex-column">
      <!-- <v-btn class="ml-auto mx-4" tile dark depressed color="#2787FF">新增船舶</v-btn> -->

      <v-dialog v-model="newWork" class="ml-auto mx-4">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="ml-auto mx-4 my-6" tile dark depressed color="#2787FF">新增船舶</v-btn>
        </template>

        <!-- :info="newWorkList"
        :shipList="shipSelectList"-->
        <dialogCard width="670" titleTxt="新增船舶" @cancel="newWork = false" @submit="newTanker">
          <template v-slot:info>
            <v-form v-model="newWorkValid">
              <v-container fluid class="pa-0 px-12">
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">船舶信息（必填项）</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船舶公司</label>
                    </div>
                    <v-select
                      :rules="rules"
                      v-model="companyId"
                      :items="companiesList"
                      item-value="id"
                      item-text="firmname"
                      background-color="#EAF4FE"
                      placeholder="请选择公司"
                      hide-details
                      flat
                      solo
                    ></v-select>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船名</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="shipName"
                      placeholder="请输入船名"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">膨胀倍数</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="expansionFactor"
                      placeholder="请输入膨胀倍数"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          <v-img
                            src="https://api.ciplat.com/tpl/default/Index/Public/image/tong.png"
                          ></v-img>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舱总数</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="cabinsNumber"
                      placeholder="请输入舱总数"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          船舶总共有多少舱
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">纵横倾修正表</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      请查阅检定证书目录确认是否有纵倾、横倾修正表
                    </v-tooltip>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="tiltCorrectionTable"
                      hide-details
                      :label="tiltCorrectionTable?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">底量测量孔</label>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="bottomMasuringHole"
                      hide-details
                      :label="bottomMasuringHole?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">舱容表有效期</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      查看有效文案底部有效期
                    </v-tooltip>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="date"
                          solo
                          hide-details
                          flat
                          prepend-inner-icon="mdi-calendar-range-outline"
                          readonly
                          v-on="on"
                          style="width:100px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker locale="zh-cn" v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">管线容量</label>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="pipelineCapacity"
                      hide-details
                      :label="pipelineCapacity?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                </v-row>
                <!-- <div>
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表图片（可选）</v-subheader>
                  </div>
                  <v-card flat color="#EAF4FF" width="100" height="100" class="d-flex flex-column justify-center" style="color:#2787FF">
                    <v-icon size="50">mdi-plus</v-icon>上传图片
                  </v-card>
                </div>-->
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <!-- 修改船舶信息 -->
      <v-dialog v-model="modifyWork" class="ml-auto mx-4">
        <dialogCard
          width="670"
          titleTxt="修改船舶信息"
          @cancel="modifyWork = false"
          @submit="modifyTanker"
        >
          <template v-slot:info>
            <v-form v-model="newWorkValid">
              <v-container fluid class="pa-0 px-12">
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">船舶信息（必填项）</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船舶公司</label>
                    </div>
                    <v-select
                      :rules="rules"
                      v-model="modifyWorList.companyId"
                      :items="companiesList"
                      item-value="id"
                      item-text="firmname"
                      background-color="#EAF4FE"
                      placeholder="请选择公司"
                      hide-details
                      flat
                      solo
                    ></v-select>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船名</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="modifyWorList.shipName"
                      placeholder="请输入船名"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">膨胀倍数</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="modifyWorList.expansionFactor"
                      placeholder="请输入膨胀倍数"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          <v-img
                            src="https://api.ciplat.com/tpl/default/Index/Public/image/tong.png"
                          ></v-img>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舱总数</label>
                    </div>
                    <v-text-field
                      :rules="rules"
                      v-model="modifyWorList.cabinsNumber"
                      placeholder="请输入舱总数"
                      background-color="#EAF4FE"
                      hide-details
                      flat
                      solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          船舶总共有多少舱
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">纵横倾修正表</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      请查阅检定证书目录确认是否有纵倾、横倾修正表
                    </v-tooltip>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="modifyWorList.tiltCorrectionTable"
                      hide-details
                      :label="modifyWorList.tiltCorrectionTable?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">底量测量孔</label>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="modifyWorList.bottomMasuringHole"
                      hide-details
                      :label="modifyWorList.bottomMasuringHole?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">舱容表有效期</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      查看有效文案底部有效期
                    </v-tooltip>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="modifyWorList.date"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="modifyWorList.date"
                          solo
                          hide-details
                          flat
                          prepend-inner-icon="mdi-calendar-range-outline"
                          readonly
                          v-on="on"
                          style="width:100px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="zh-cn"
                        v-model="modifyWorList.date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(modifyWorList.date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">管线容量</label>
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="modifyWorList.pipelineCapacity"
                      hide-details
                      :label="modifyWorList.pipelineCapacity?'包含':'未包含'"
                    ></v-switch>
                  </v-col>
                </v-row>
                <!-- <div>
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表图片（可选）</v-subheader>
                  </div>
                  <v-card flat color="#EAF4FF" width="100" height="100" class="d-flex flex-column justify-center" style="color:#2787FF">
                    <v-icon size="50">mdi-plus</v-icon>上传图片
                  </v-card>
                </div>-->
              </v-container>
            </v-form>
          </template>
        </dialogCard>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="tankerList"
        hide-default-footer
        class="mx-4"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="8"
        style="border:2px solid rgba(39,135,255,0.3);"
      >
        <template v-slot:item.action="{ item }">
          <!-- 修改船舶信息 -->
          <div class="d-flex">
            <v-icon small class="mr-2" @click="modifyShip(item)">mdi-pencil</v-icon>
            <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
            <v-img
              max-width="15"
              @click="$router.push({name:'CabinManagement',query: item})"
              src="../../assets/images/ship_management_icon_btn.png"
            ></v-img>
          </div>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          max-width="500"
          outlined
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import dialogCard from "@/components/common/dialogCard";
export default {
  components: {
    dialogCard: dialogCard
  },
  data: () => ({
    rules: [v => !!v],
    newWorkValid: false,
    newWork: false,
    modifyWork: false,

    infonList: null,
    tankerList: [],
    companiesList: [],
    page: 1,
    pageCount: 0,
    // 公司id
    companyId: "",
    // 船名
    shipName: "",
    // 膨胀倍数
    expansionFactor: "",
    // 舱总数
    cabinsNumber: "",
    // 纵倾修正表
    tiltCorrectionTable: false,
    // 底量测量孔
    bottomMasuringHole: false,
    // 舱容表有效期
    menu: false,
    date: new Date().toISOString().substr(0, 10),
    //管线容量
    pipelineCapacity: false,
    modifyWorList: {
      // 公司id
      companyId: "",
      // 船名
      shipName: "",
      // 膨胀倍数
      expansionFactor: "",
      // 舱总数
      cabinsNumber: "",
      // 纵倾修正表
      tiltCorrectionTable: false,
      // 底量测量孔
      bottomMasuringHole: false,
      // 舱容表有效期
      menu: false,
      date: "",
      //管线容量
      pipelineCapacity: false
    },
    headers: [
      {
        text: "船名",
        align: "start",
        sortable: false,
        value: "shipname",
        class: "head-style title"
      },
      { text: "膨胀倍数", value: "coefficient", class: "head-style title" },
      { text: "舱总数", value: "cabinnum", class: "head-style title" },
      { text: "管线容量", value: "is_guanxian", class: "head-style title" }, // 1:包含，2:不包含
      { text: "底量测量孔", value: "is_diliang", class: "head-style title" }, // 1:包含，2:不包含
      { text: "纵倾修正表", value: "suanfa", class: "head-style title" }, // b、c:有 ，a、d：没有
      { text: "操作", value: "action", class: "head-style title" }
    ]
  }),
  created() {
    this.info();
  },
  methods: {
    newTanker() {
      if (this.newWorkValid) {
        this.$server
          .newTanker({
            firmid: this.companyId,
            shipname: this.shipName,
            coefficient: this.expansionFactor,
            cabinnum: this.cabinsNumber,
            suanfa: this.tiltCorrectionTable ? "b" : "a",
            is_guanxian: this.pipelineCapacity ? "1" : "2",
            is_diliang: this.bottomMasuringHole ? "1" : "2",
            expire_time: this.date
          })
          .then(data => {
            if (data.code === 1) {
              this.info();
              this.newWork = false;
              this.bus.$emit("tips", {
                show: true,
                title: "新建成功"
              });
            } else {
              this.bus.$emit("tips", {
                show: true,
                title: data.error
              });
            }
          });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "请输入必填项"
        });
      }
    },
    info() {
      //获取用户基础信息
      this.infonList = JSON.parse(localStorage.getItem("info"));
      // 获取管理员液货船信息
      this.$server
        .tankerInformation({ firmid: this.infonList.firmid })
        .then(data => {
          this.companiesList = data.firmlist;
          this.tankerList = data.list;
          for (const i in this.tankerList) {
            this.tankerList[i].is_guanxian =
              this.tankerList[i].is_guanxian == 1 ? "包含" : "不包含";
            this.tankerList[i].is_diliang =
              this.tankerList[i].is_diliang == 1 ? "包含" : "不包含";
            this.tankerList[i].suanfa =
              this.tankerList[i].suanfa == "b" ||
              this.tankerList[i].suanfa == "c"
                ? "有"
                : "无";
          }
        });
    },
    // 修改船舶信息
    modifyShip(data) {
      this.modifyWork = true;
      this.modifyWorList.id = data.id;
      this.modifyWorList.shipName = data.shipname;
      this.modifyWorList.companyId = data.firmid;
      this.modifyWorList.expansionFactor = data.coefficient;
      this.modifyWorList.cabinsNumber = data.cabinnum;
      this.modifyWorList.tiltCorrectionTable =
        data.suanfa == "有" ? true : false;
      this.modifyWorList.pipelineCapacity =
        data.is_guanxian == "包含" ? true : false;
      this.modifyWorList.bottomMasuringHole =
        data.is_diliang == "包含" ? true : false;
      this.modifyWorList.date = this.$moment
        .unix(data.expire_time)
        .format("YYYY-MM-DD");
    },
    modifyTanker() {
      // modifyTanker
      if (this.newWorkValid) {
        this.$server
          .modifyTanker({
            id: this.modifyWorList.id,
            shipname: this.modifyWorList.shipName,
            coefficient: this.modifyWorList.expansionFactor,
            cabinnum: this.modifyWorList.cabinsNumber,
            suanfa: this.modifyWorList.tiltCorrectionTable ? "b" : "a",
            is_guanxian: this.modifyWorList.pipelineCapacity ? "1" : "2",
            is_diliang: this.modifyWorList.bottomMasuringHole ? "1" : "2",
            expire_time: this.modifyWorList.date
          })
          .then(data => {
            if (data.code === 1) {
              this.info();
              this.modifyWork = false;
              this.bus.$emit("tips", {
                show: true,
                title: "修改成功"
              });
            } else {
              this.bus.$emit("tips", {
                show: true,
                title: data.error
              });
            }
          });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "请输入必填项"
        });
      }
    }
  }
};
</script>

<style>
.head-style {
  color: #2787ff !important;
}
</style>